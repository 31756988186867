<template>

    <div v-if="stateCheck">
      <div class="user-flow-logo">
        <img src="~@/assets/excelerate-logo.png" alt="Excelerate" title="Excelerate">
      </div>
      <div class="user-flow">
        <div class="user-splash">

        </div>

        <div class="row">
          <div class="col-12 user-form">
            <form @submit.prevent="verifyCodes()">
              <h4>Verify your email</h4>
              <h6 class="mt-4">Check your inbox</h6> 
              <p>
                We've sent an email to <strong class="color-first">{{ user.email }}</strong> with your <strong>6 digits activation code</strong>.
              </p>
              <div class="form-group form-row mt-4">
                <div class="col-12">
                  <input type="number" class="form-control form-control-lg text-center" placeholder="000000" v-model="verificationCode" required>
                </div>
              </div>
              <button class="btn btn-lg btn-primary btn-block">
                Verify code
              </button>

              <div class="alert alert-success mt-3" v-if="verifyMessageSuccess">{{ verifyMessageSuccess }}</div>
              <div class="alert alert-danger mt-3" v-if="verifyMessageError">{{ verifyMessageError }}</div>
                
              <br><br>
              <div class="text-center">
                <div>Didn't recieve any code?</div> 
                <button class="btn btn-secondary btn-sm" type="button" @click="resendVerificationEmail()">Resend verification code</button>
              </div>
              <div class="text-center mt-4 mb-2">
                <button class="btn btn-link btn-sm" type="button" @click="logoutFromFirebase()">Log out</button>
              </div>
              </form>

          </div>
        </div>
      </div>

      <div class="mt-3 mb-5 text-center">
        <!-- maybe here -->
        <router-link to="/dashboard/" class="btn btn-link" v-if="user.verified && user.status === 'ok'">Back to dashboard</router-link>
      </div>
    </div>
</template>

<script>
// import SignInForm from '@/components/auth/SignInForm.vue'
import {mapState} from 'vuex'


export default {
    name: 'SignIn',
    data() {
      return {
        verifyMessageSuccess: '',
        verifyMessageError: '',
        verificationCode: '',
        userCode: '',
      }
    },
    components: {
        // SignInForm
    },
    computed: {
    ...mapState(['user']),
      stateCheck: function() {
        var vm = this;
        vm.userCode = vm.user.verificationCode;
        return true
      },
    },
    methods: { 

      resendVerificationEmail: function() {
        var vm = this;
        const verificationEmailData = {
          toEmail: this.user.email,
          verificationCode: this.user.verificationCode
        }
        // console.log("resendVerificationEmail: " + JSON.stringify(verificationEmailData));
        vm.$store.dispatch('sendVerificationMail', verificationEmailData).then(() => {
          vm.verifyMessageSuccess = "Check your inbox";
        });
      },
      verifyCodes: function() { 
        var vm = this;
        this.verifyMessageSuccess = '';
        this.verifyMessageError = '';

        var userVerificationCode = vm.vCode;
        var inputCode = this.verificationCode;

        // console.log("verifyCodes:")
        // console.log(vm.userCode + " " + inputCode);
        if(parseInt(vm.userCode) === parseInt(inputCode)) {
          vm.verifyMessageSuccess = "Code accepted";
          vm.$store.dispatch('updateUserVerification', true);
        } else {
          vm.verifyMessageError = "No match. Please check your code and try again.";
        }
      },
      logoutFromFirebase() {
         this.$store.dispatch('signOutAction');
       }
    }
}
</script>

<style>

</style>